<template>
  <list-condition-template ref="table" :rows="rows" :total="total" @page-info-change="handlePageInfoChange" v-model:listQuery="listQuery"
                           :batch-column="true" @selected="selectedProducers" :defaultSort="{prop:'updateTime',order:'descending'}">
    <template #condition>
      <el-form-item>
        <el-input @keyup.enter="handleFilter" placeholder="名称或账号" v-model="listQuery.condition.keyword" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-select @change="handleFilter" clearable style="margin-left: 10px; width: 150px" class="filter-item" placeholder="启用状态" v-model="listQuery.condition.enabled">
          <el-option v-for="item in stateOptions" :key="item.name" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleFilter">搜索</el-button>
      </el-form-item>
      <el-row type="flex" justify="space-between">
        <el-button class="filter-item" @click="handleCreate" type="success" size="mini" icon="el-icon-plus" v-waves>新建</el-button>
        <div>
          <el-button class="filter-item" @click="setProducerStatus('NO')" type="danger" size="mini" v-waves>停用账号</el-button>
          <el-button class="filter-item" @click="setProducerStatus('YES')" type="success" size="mini" v-waves>启用账号</el-button>
          <el-button class="filter-item" @click="resetProducerPasswordBatch" type="info" size="mini" v-waves>重置密码</el-button>
        </div>
      </el-row>
    </template>
    <template #columns>
      <el-table-column min-width="150px" label="名称" align="center">
        <template  #default="scope">
          <span>{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="150px" label="账号" align="center">
        <template #default="scope">
          <span>{{scope.row.account}}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="150px" label="生产线" align="center" property="scanLine"/>
      <el-table-column min-width="150px" label="ODM制造商" align="center">
        <template #default="scope">
          <span>{{scope.row.manufacturer.name}}</span>
        </template>
      </el-table-column>
      <el-table-column class-name="status-col" align="center" label="账号状态" width="100px">
        <template  #default="scope">
          <el-tag :type="showFilter(scope.row.enabled)">{{scope.row.enabled ==='YES' ? '启用' : '停用'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="350px" class-name="fixed-width">
        <template  #default="scope">
          <el-button type="primary" size="mini" @click="handleUpdate(scope.row)" v-waves>编辑</el-button>
          <el-button size="mini" :type="showButtonFilter(scope.row.enabled)" @click="handleState(scope.row)" v-waves>{{scope.row.enabled === 'YES' ? '停用' : '启用'}}</el-button>
          <el-button size="mini" type="primary" @click="handleChangePassword(scope.row)" v-waves>修改密码</el-button>
          <el-button type="danger" size="mini" @click="handleDelete(scope.row)" v-waves>删除</el-button>
        </template>
      </el-table-column>
    </template>

<!--新增、编辑、修改密码弹窗-->
    <el-dialog :title="title" v-model="dialogVisible" width="40%" :close-on-click-modal="false" :show-close="true">
      <el-form size="mini" ref="producerForm" :model="producerForm" :rules="rules" label-width="120px" status-icon>
        <el-form-item label="用户名称" prop="name">
          <el-input :disabled="dialogType === 3" v-model.trim="producerForm.name" maxlength="25" show-word-limit placeholder="请输入用户名称"></el-input>
        </el-form-item>
        <el-form-item label="系统账号" prop="account">
          <el-input :disabled="dialogType !== 1" v-model="producerForm.account" maxlength="20" show-word-limit placeholder="请输入生产管理用户系统账号"></el-input>
        </el-form-item>
        <el-form-item v-if="dialogType === 1" label="用户密码" prop="password">
          <el-input type="password" placeholder="请输入用户密码" v-model.trim="producerForm.password" auto-complete="new-password" readonly onfocus="this.removeAttribute('readonly')"></el-input>
        </el-form-item>
        <el-form-item label="生产线" prop="scanLine">
          <el-input :disabled="dialogType === 3" v-model.trim="producerForm.scanLine" maxlength="25" show-word-limit placeholder="请输入用户生产线"></el-input>
        </el-form-item>
        <el-form-item v-if="dialogType === 1 || dialogType === 2" label="启用" prop="state">
          <el-switch v-model="state" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item v-if="dialogType === 3" label="新密码" prop="password">
          <el-input type="password" placeholder="请输入密码" v-model="producerForm.password" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="dialogType === 3" label="确认密码" prop="checkPassword">
          <el-input type="password" placeholder="请再次输入密码" v-model="producerForm.checkPassword" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer class="dialog-footer">
        <el-button size="mini" @click="dialogVisible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="handleSubmit" :loading="loading">{{loading ? '提交中' : '确定'}}</el-button>
      </template>
    </el-dialog>
  </list-condition-template>
</template>

<script>
  import listConditionTemplate from '@/components/templates/listConditionTemplate'
  import waves from "@/directive/waves";
  import scannerApi from "@/apis/scanner/scanner";

  export default {
    directives: {
      waves
    },
    components: {
      listConditionTemplate
    },
    data () {
      return {
        rows: [],
        total: 0,
        selectProducers: [],
        listQuery: {
          condition:{
            keyword: null,
            enabled: null
          },
          pageInfo:{
            pageIndex: 1,
            pageSize: 20,
            orders: []
          }
        },
        stateOptions: [{name: '启用',value: 1},{name: '停用',value: 0}],
        dialogVisible: false,
        dialogType: 0, // 1-新增，2-编辑，3-修改密码
        title: '',
        producerForm: {
          id: null,
          name: '',
          account: '',
          password: '',
          scanLine: '',
          checkPassword: '',
          state: 1,
        },
        state: 1,
        rules: {
          name: [
            {required: true, message: '请输入用户名称'},
            {max: 25, message: '制造商名称长度不超过25个字符', trigger: 'change'},
            {pattern: /^[-_()（）a-z0-9A-Z\u4e00-\u9fa5]+$/, message: '只能输入中文、数字、字母、短横线、下划线和中英文小括号'}
          ],
          account: [
            {required: true, message: '请输入生产管理用户账号'},
            {max: 20, message: '账号长度需少于20个字符', trigger: 'change'},
            {pattern: /^[0-9A-Za-z]{1,20}$/, message: '只能输入数字、字母'}
          ],
          password: [
            {required: true, message: '请输入用户密码'},
            {min: 6, max: 20, message: '密码长度在6到20个字符', trigger: 'change'},
            {pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/, message: '密码必须是数字和字母组合'},
            {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入密码'))
              } else if (value.length < 6 || value.length > 20) {
                callback(new Error('密码长度需在6到20之间'))
              } else {
                if (this.producerForm.checkPassword !== '') {
                  this.$refs.producerForm.validateField(
                    'checkPassword'
                  )
                }
                callback()
              }
            }
          }],
          checkPassword: [
            {required: true, message: '请输入用户密码'},
            {min: 6, max: 20, message: '密码长度在6到20个字符', trigger: 'change'},
            {pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/, message: '密码必须是数字和字母组合'},
            {
              validator: (rule, value, callback) => {
                if (value === '') {
                  callback(new Error('请再次输入密码'))
                } else if (value.length < 6 || value.length > 20) {
                  callback(new Error('密码长度需在6到20之间'))
                } else if (value !== this.producerForm.password) {
                  callback(new Error('两次输入密码不一致'))
                } else {
                  callback()
                }
              }
            }
          ]
        },
        loading: false
      }
    },
    created() {
    },
    methods: {
      getList (pageInfo) {
        console.log('123')
        scannerApi.getPaged(this.listQuery).then(response=>{
          this.rows = response.data.content
          this.total = response.data.totalElements
        })
      },
      /**
       * 搜索条件变更处理
       */
      handleFilter (pageInfo) {
        this.getList(pageInfo)
      },
      showFilter (show) {
        return show === 'YES' ? 'success' : 'danger'
      },
      showButtonFilter (show) {
        return show !== 'YES' ? 'success' : 'danger'
      },
      /**
       * 分页信息变动事件处理
       * @param  {Object} pageInfo 分页信息
       */
      handlePageInfoChange (pageInfo) {
        this.getList(pageInfo)
      },
      selectedProducers(data) {
        this.selectProducers = data
      },
      /**
       * 新增按钮点击处理
       */
      handleCreate () {
        this.title = '新增生产管理用户'
        this.dialogVisible = true
        this.dialogType = 1
        this.resetProducerForm()
      },
      setProducerStatus (status) {
        let condition = {
          enabled: status,
          accountIds: []
        }
        if(this.selectProducers && this.selectProducers.length > 0){
          condition.accountIds = this.selectProducers.map(s => {
            return s.accountId
          })
          scannerApi.resetStatusBatch(condition).then(response=>{
            this.selectProducers = []
            this.getList()
          })
        }else{
          this.$message.warning('请选择')
        }
      },
      resetProducerPasswordBatch () {
        if(this.selectProducers && this.selectProducers.length > 0){
          let producers = this.selectProducers.map(s => {
            return s.accountId
          })
          scannerApi.resetPasswordBatch(producers).then(response=>{
            this.selectProducers = []
            this.$message.success('重置成功')
            this.getList()
          })
        }else{
          this.$message.warning('请选择')
        }
      },
      handleUpdate(row) {
        this.title = '编辑生产管理用户'
        this.dialogVisible = true
        this.dialogType = 2
        this.producerForm = JSON.parse(JSON.stringify(row))
        this.producerForm.state = row.enabled === 'YES' ? 1 : 0
        this.state = this.producerForm.state
      },
      handleState(row) {
        let isEnabled = ''
        isEnabled = row.enabled !== 'YES';
        scannerApi.setState({ accountId: row.accountId, enabled: isEnabled }).then(response => {this.getList()})
      },
      handleChangePassword(row) {
        this.title = '修改密码'
        this.dialogVisible = true
        this.dialogType = 3
        this.producerForm = JSON.parse(JSON.stringify(row))
      },
      handleDelete(row) {
        this.$confirm('删除后无法恢复,确定要删除该用户吗？', '警告', {
          type: 'warning'
        }).then(() => {
          scannerApi.delete(row.id).then(res => {
            if (res.data) {
              this.handleFilter()
            } else {
              this.$message.error({
                title: '发生错误',
                message: res.msg === '' ? '删除生产管理用户失败，请稍后重试！！！' : res.msg,
                duration: 0,
                position: 'bottom-right'
              })
            }
          })
        })
      },
      handleSubmit() {
        this.$refs.producerForm.validate().then(response => {
          if (!response) {
            return
          }
          this.producerForm.enabled = this.state === 1 ? 'YES' : 'NO'
          this.producerForm.state = this.state
          if (this.dialogType === 1) {
            scannerApi.insert(this.producerForm).then(response => {
              this.getList()
              this.$message.success('生产管理用户创建成功')
            })
          }else if (this.dialogType === 2) {
            scannerApi.update(this.producerForm).then(response => {
              this.getList()
              this.$message.success('生产管理用户更新成功')
            })
          }else if (this.dialogType === 3) {
            scannerApi.changePassword({id: this.producerForm.accountId,newPassword: this.producerForm.password}).then(response => {
              this.$message.success('密码修改成功')
            })
          }
          this.dialogVisible = false
        }).catch(error => {
          this.$message.error('生产管理用户创建/更新失败')
        })
      },
      resetProducerForm() {
        this.producerForm = {
          id: null,
          name: '',
          account: '',
          scanLine: '',
          password: '',
          checkPassword: '',
          state: 1,
        }
      }
    }
  }
</script>
