import api from '@/apis/api'

/**
 * 扫码生产相关api
 */
export class scannerApi extends api {
    /**
     * 创建新的管理员
     * @param {*} 对象内容
     */
    insert (form) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/register`,
            method: 'post',
            data: form
        })
    }

    /**
     * 更新已存在的对象
     * @param {*} 对象内容
     */
    update (form) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}`,
            method: 'put',
            data: form
        })
    }
    setState (form) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/setScannerStatus`,
            method: 'put',
            data: form
        })
    }
    changePassword (form) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/changeScannerPassword`,
            method: 'post',
            data: form
        })
    }
    /**
     * 删除对象
     * @param {*} 唯一id
     */
    delete (id) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/${id}`,
            method: 'delete'
        })
    }
    resetStatusBatch (condition) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/setScannerStatusBatch`,
            method: 'put',
            data: condition
        })
    }
    resetPasswordBatch(condition){
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/resetPasswordBatch`,
            method: 'post',
            data: condition
        })
    }
}

export default new scannerApi('scanner')
